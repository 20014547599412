jQuery(document).on('change', 'input[name="sub_payment_method"]', function (ev) {
    if( this.value == 'CC') {
        jQuery('select[name="wc-paygate-payment-token"]').removeClass('start_hidden');
        jQuery('select[name="wc-paygate-payment-token"]').css('display', 'block');
        jQuery('div[name="wc-paygate-payment-token"]').removeClass('start_hidden');
        jQuery('div[name="wc-paygate-payment-token"]').css('display', 'block');
    } else {
        jQuery('select[name="wc-paygate-payment-token"]').css('display', 'none');
        jQuery('div[name="wc-paygate-payment-token"]').css('display', 'none');
    }
})
